






























































































































































































































































































import {
  defineComponent,
  onBeforeMount,
  onMounted,
  ref,
  Ref,
} from '@vue/composition-api'
import { useActions, useGetters, useState } from '@u3u/vue-hooks'
import dayjs from 'dayjs'

import MyBox from '@/components/my/Box.vue'
import {
  cadran,
  convertRegister,
  convertUnit,
  trimzerosIndex,
} from '@/inc/utils'
import { toCamel } from '@/inc/utils/case'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import Breadcrumb from '@/components/Breadcrumb.vue'
import CustomLink from '@/components/g/CustomLink.vue'
import { AdresseEan, authorizationTypes, IndexData } from '@/inc/types'
import Toggle from '@/components/ui/Toggle.vue'
import BlockTips from '@/components/ui/BlockTips.vue'
import AdresseSwitcher from '@/components/commune/components/AdresseSwitcher.vue'
import { groupArrayOfObjects, toHours } from '@/inc/utils/utils'

const { VUE_APP_DOMAIN } = process.env

export default defineComponent({
  name: 'commune-compteur-details',
  components: {
    AdresseSwitcher,
    MyBox,
    Breadcrumb,
    CustomLink,
    Toggle,
    BlockTips,
  },
  setup(props, ctx) {
    const { $route } = ctx.root
    const { ean, compteur } = $route.params
    const myState = {
      ...useState('my', ['i18n']),
    }
    const communeState = {
      ...useState('commune', ['i18n']),
    }
    const { chrome } = useState(['chrome'])
    const globalI18n = chrome.value.data.i18n
    const myI18n = myState.i18n
    const communeI18n = communeState.i18n
    const { authorizations } = useGetters('user', ['authorizations'])
    const { getEans } = useGetters('commune', ['getEans'])
    const { FETCH_EANS } = useActions('commune', ['FETCH_EANS'])
    const breadcrumbPage = [
      {
        label: communeState.i18n.value.communeIndex.title?.replace(
          /<[^>]*>/g,
          ''
        ),
        url: { name: 'CommuneIndex' },
      },
      { label: communeState.i18n.value.breadcrumb.detailsCompteur, url: '' },
    ]
    const isLoading: Ref<boolean> = ref(true)
    const meterData: Ref = ref()
    const indexData: Ref<IndexData[] | undefined> = ref()
    const adresses: Ref<AdresseEan[]> = ref([])
    const releveIndexUrl = ref(
      `${VUE_APP_DOMAIN}/i?e=${ean}&c=${compteur}&tr=an&commune=true`
    )

    const loadDatas = (codeEan: string) => {
      isLoading.value = true
      axios
        .get(`${getApiUrl()}/town/index/ean/${codeEan}`)
        .then(async response => {
          meterData.value = await response.data
          for (let i = 0; i < meterData.value.compteurs.length; i++) {
            if (
              meterData.value.compteurs[i].numCpt === compteur &&
              meterData.value.compteurs[i].cptSmart
            ) {
              meterData.value.cptSmart = meterData.value.compteurs[i].cptSmart
              break
            }
          }
        })
        .catch(error => {
          console.error(error)
          isLoading.value = false
        })

      axios
        .get(`${getApiUrl()}/town/index/historique/${codeEan}`)
        .then(async response => {
          indexData.value = await response.data
          // keep only data for the current meter
          if (indexData.value && indexData.value.length > 0) {
            indexData.value = groupArrayOfObjects(indexData.value, 'numCpt')
            let meter = ''
            if (isNaN(Number(compteur))) {
              meter = compteur
            } else {
              meter = parseInt(compteur, 10).toString()
            }
            if (indexData.value) {
              indexData.value = indexData.value[meter]
            }
            // keep the last two index form the list
            if (indexData.value && indexData.value.length > 2) {
              indexData.value = indexData.value.slice(-2)
            }

            if (indexData.value === undefined) {
              indexData.value = []
            }
          }
        })
        .catch(error => {
          console.error(error)
          isLoading.value = false
        })

      isLoading.value = false
    }

    onBeforeMount(() => {
      adresses.value = getEans.value
      if (!adresses.value || adresses.value.length === 0) {
        FETCH_EANS().then(async () => {
          adresses.value = await getEans.value
        })
      }
      loadDatas(ean)
    })

    onMounted(() => {
      console.log('Mounted DetailsCompteur')
    })

    const checkPortP1 = () => {
      // TODO
      console.log('TODO checkPortP1')
    }

    const changeMeter = async data => {
      await ctx.root.$router.push({
        name: 'CommuneDetailsCompteur',
        params: {
          ean: data.ean,
          compteur: data.meter,
        },
      })
      loadDatas(ean)
    }

    return {
      dayjs,
      cadran,
      convertUnit,
      convertRegister,
      trimzerosIndex,
      toCamel,
      toHours,
      ean,
      compteur,
      globalI18n,
      myI18n,
      communeI18n,
      authorizations,
      authorizationTypes,
      breadcrumbPage,
      isLoading,
      meterData,
      indexData,
      checkPortP1,
      adresses,
      releveIndexUrl,
      changeMeter,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
