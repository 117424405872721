import dayjs from 'dayjs'

export const getDateId = () => {
  const random = Math.floor(Math.random() * 1000).toString()
  const date = dayjs().format('DD_MM_YY_HH[h]mm[m]ss[s]')

  return ''.concat(date, '_', random)
}

export const findKey = (obj, value) =>
  Object.keys(obj).find(key => obj[key] === value)

export const removeAccents = (str: string | null) => {
  if (str && str.length > 0) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  }

  return ''
}

export const groupArrayOfObjects = (list, key) =>
  list.reduce((rv, x) => {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)

    return rv
  }, {})

export const toHours = hour => hour.replace(':', 'h')
