


































































import { defineComponent } from '@vue/composition-api'

interface Box {
  title: string
  subtitle?: string
  styleClass?: string
  noSeparator?: boolean
}

export default defineComponent({
  name: 'my-box',
  props: {
    content: {
      required: true,
      type: Object as () => Box,
    },
  },

  setup() {
    return {}
  },
})
