













import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'toggle',
  components: {},
  props: {
    checked: Boolean,
    disabled: Boolean,
    color: {
      type: String,
      required: false,
      default: 'orange',
    },
  },

  setup() {
    return {}
  },
})
