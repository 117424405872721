







import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'block-tips',
  components: {},
  props: {
    title: {
      type: String,
      required: true,
    },
    content: {
      type: String,
      required: true,
    },
  },
})
