

































































































import { useState } from '@u3u/vue-hooks'
import { defineComponent, onBeforeMount, Ref, ref } from '@vue/composition-api'
import { AdresseEan } from '@/inc/types'
import { removeAccents } from '@/inc/utils/utils'

export interface AdresseChoice {
  id: number
  rue: string
  numRue: string
  codePostal: number
  localite: string
  active: boolean
}

export interface EanChoice {
  id: number
  idAdresse: number
  ean: string
  compteur: string
  type: string
  active: boolean
}

export default defineComponent({
  name: 'adresse-switcher',
  components: {},
  props: {
    datas: {
      required: true,
      type: Array as () => Array<AdresseEan>,
    },
    ean: {
      required: true,
      type: String,
    },
    meter: {
      required: true,
      type: String,
    },
  },

  setup(props, ctx) {
    const { i18n } = useState('my', ['i18n'])
    const adresses: Ref<any[]> = ref([])
    const eans: Ref<EanChoice[]> = ref([])
    const filteredEans: Ref<EanChoice[]> = ref([])
    const showAddresses: Ref<boolean> = ref(false)
    const showMeters: Ref<boolean> = ref(false)
    const currentMeter: Ref<string> = ref(props.meter)
    const search: Ref<boolean> = ref(true)
    const searchTerms = ref('')
    const results: Ref<any[]> = ref([])

    /**
     * Sort adresses by removing the active element, sort by rue then put the active element on top
     */
    const sortAdresses = () => {
      const el: AdresseChoice = adresses.value.find(a => a.active)
      const i: number = adresses.value.findIndex(a => a.active)
      adresses.value.splice(i, 1)
      adresses.value.sort((a, b) => {
        if (a.rue > b.rue) {
          return 1
        } else if (a.rue < b.rue) {
          return -1
        }

        return 0
      })
      adresses.value.unshift(el)
    }

    onBeforeMount(() => {
      const indexAdr = props.datas.findIndex(a =>
        a.eans.some(ean => ean.numCpt === currentMeter.value)
      )
      props.datas.forEach((item, index) => {
        adresses.value.push({
          id: index,
          rue: item.adresse.rue,
          numRue: item.adresse.numRue,
          codePostal: item.adresse.codePostal,
          localite: item.adresse.localite,
          active: index === indexAdr,
        })

        item.eans.forEach((ean, indexEan) => {
          eans.value.push({
            id: indexEan,
            idAdresse: index,
            ean: ean.ean,
            compteur: ean.numCpt,
            type: ean.type,
            active: currentMeter.value === ean.numCpt,
          })
        })
      })

      sortAdresses()
      filteredEans.value = eans.value.filter(ean => ean.idAdresse === indexAdr)
      filteredEans.value.sort((a, b) => (a.active && !b.active ? -1 : 1))
      results.value = adresses.value
    })

    const closeAll = () => {
      showAddresses.value = false
      showMeters.value = false
    }

    const deployAddresses = () => {
      showAddresses.value = !showAddresses.value
      showMeters.value = false
    }

    const deployMeters = () => {
      showMeters.value = !showMeters.value
      showAddresses.value = false
    }

    const selectAddress = (adr: number) => {
      showMeters.value = false
      showAddresses.value = false
      // change the active address
      adresses.value.forEach(a => (a.active = false))
      const el = adresses.value.find(a => a.id === adr)
      if (el) {
        el.active = true
        sortAdresses()
        filteredEans.value = eans.value.filter(ean => ean.idAdresse === el.id)
        filteredEans.value[0].active = true
        ctx.emit('changeMeter', {
          ean: filteredEans.value[0].ean,
          meter: filteredEans.value[0].compteur,
        })
      }
    }

    const selectMeter = (ean: string, meter: string) => {
      showMeters.value = false
      showAddresses.value = false
      // emit meter (ean) change
      ctx.emit('changeMeter', { ean, meter })
      // change active meter
      filteredEans.value.forEach(e => (e.active = false))
      const el = filteredEans.value.find(e => e.ean === ean)
      if (el) {
        el.active = true
        filteredEans.value.sort((a, b) => (a.active && !b.active ? -1 : 1))
      }
      currentMeter.value = meter
    }

    const filterResults = () => {
      showAddresses.value = true
      results.value = adresses.value.filter(
        a =>
          removeAccents(a.rue)
            .toLowerCase()
            .includes(removeAccents(searchTerms.value).toLowerCase()) ||
          a.codePostal.toString().includes(searchTerms.value.toLowerCase()) ||
          removeAccents(a.localite)
            .toLowerCase()
            .includes(removeAccents(searchTerms.value).toLowerCase())
      )
    }

    const communeState = {
      ...useState('commune', ['i18n']),
    }

    return {
      i18n,
      i18nCommune: communeState.i18n,
      adresses,
      filteredEans,
      deployAddresses,
      deployMeters,
      showAddresses,
      showMeters,
      selectAddress,
      selectMeter,
      closeAll,
      search,
      searchTerms,
      filterResults,
      results,
      apiDomain: process.env.VUE_APP_API_DOMAIN,
    }
  },
})
